import React from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Home from "../Component/Mainpage/Home";



const LandingPage = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  return (
    <div>
      {" "}
      {!smallScreen && (

        <div className="mainContent">
          <Home />
        </div>
      )}
      {/* This is used For the mobile view Blue print Map*/}
      {smallScreen && (
        <div className="back_other_home">
        <div className="mainContent">
          <Home />
        </div></div>
      )}
    </div>
  );
};

export default LandingPage;
