import React from 'react'
import { Grid } from "@mui/material";
import Navbar from "../Navbar/Navbar";

import token from './rddd.png'
import tokenn from './rff.png'

function TeamPage() {
  return (
    <div> <Navbar />
    <div className='container'>    <Grid container spacing={2}>
          <Grid item md={12} lg={12} xs={12} sn={12}>
        
            <br />
            <br />
            <h1 className="about">PEN MAP</h1>
          
                 
       </Grid>
       
    <Grid item md={12} lg={12} xs={12} sn={12}>
  
        <h1 className="about" style={{textAlign:"center"}}>Phase 1</h1>
        <img src={token} className='Tokenimage'/>
         <br/>
        
    </Grid>

    <Grid item md={12} lg={12} xs={12} sn={12}>
    <br/>  
        <h1 className="about" style={{textAlign:"center"}}>Phase 2</h1>
        
        <img src={tokenn} className='Tokenimage'/>
         <br/>
        
    </Grid>

        </Grid></div>

    
        <br/>   <br/>   <br/>   <br/>
    </div>
  )
}

export default TeamPage