import React from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AboutPage from "../Component/AboutPage/TeamPage";
const LandingPage = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  return (
    <div>
      {" "}
      {!smallScreen && (
        <div className="back_other">
          <AboutPage />
        </div>
      )}
      {/* This is used For the mobile view Blue print Map*/}
      {smallScreen && (
        <div className="back_other">
          <AboutPage />
        </div>
      )}
    </div>
  );
};

export default LandingPage;
