import React from "react";
import { Grid } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import btc from "./bulo.png";

function AboutPage() {
  return (
    <div className="">
      <Navbar />
      <div className="container">
        {" "}
        <Grid container spacing={2}>
          <Grid item md={6} lg={6} xs={12} sn={12}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1 className="about">About BULLygon</h1>
            <br />
            <h1 className="aboutp">
            BULLygon aims to build a dynamic NFT collection with a unique twist. Our NFTs evolve post-mint, gaining enhanced traits and physical attributes. They also hold the potential for future utility through a modular contract extension, expertly engineered by our partners, 0xPioneers. Our collection boasts 593 captivating artworks of BULLs.
            <br /> <br />
In our Discord community, we've introduced an engaging system where every interaction earns you 25 BULLygon engagement points for each message or reaction, along with 50 BULLygon points for daily quests. These engagement points can be seamlessly converted and withdrawn into the $BULL token at a predetermined ratio upon liquidity provision.
<br /> <br />
When you mint a BULLygon NFT at a price of 25 Matic, you're not just acquiring an NFT; you're investing in the $BULL token as well. This innovative approach allows NFT buyers to actively participate in the Initial Token Offering (ITO) of the $BULL token. Each NFT is intrinsically linked to a portion of the token, tied to its unique index rather than a wallet. These tokens can be claimed progressively through a modular smart contract deployment, enhancing the NFT's inherent value. The process of harvesting and claiming mints tokens directly into your wallet, ensuring a fully decentralized token distribution methodology, distinct from traditional secured token distributions.
            </h1>
            <br />
            <br />

            <br />
            <br />
            <h1 className="about">Staking</h1>
            <br />
            <h1 className="aboutp">
           

            BULLygon NFTs would be automatically staked(Training your BULLs) simply by holding it in your wallet. 
            <br /> <br />
The auto stake rewards will be harvested from your BULLygon NFTs, Harvested rewards can be used to feed your BULLs and upgrade them over time,you will also be able buy $BULL from the DEX after LP placement and initial NFT sale
All the BULLygon NFTs will begin at level 1, stay tuned for more details on how to upgrade to level 2 and above.
You get to earn more $BULL tokens over a certain period of time based upon the amount of BULLygon Nfts held in your wallet. 
Your earnings continues over time utilizing the engage-to-earn discord utility. Holding the $BULL token and a BULLygon NFT gives you a guaranteed access to our first airdrop and potentailly other future developments & partnerships. We are looking at a 6months time frame for token emittance per NFT - with additional rewards provided through the engage-to-earn discord service. (This plan is ammendable and may be subject to change. Always await updates directly from our twitter page or our discord server and not anywhere else).
            </h1> <br /> <br /> <br /> <br /> <br /> <br /><br /> <br />
          </Grid>
          <Grid item md={6} lg={6} xs={12} sn={12}>
            <img src={btc} className="btc" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AboutPage;
