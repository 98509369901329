import React, { useEffect, useState, useCallback } from 'react'
import Navbar from '../Component/Navbar/Navbar'
import Mints from './pok.png'
import { Grid } from '@mui/material'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { useWalletConnect, useMint } from "../hooks/useNFT";

function Mint() {

  const { isLoading, pendingConnector, connectError, isConnected, connect } =
    useWalletConnect();
  const {
    mint,
    contractData: { stage, totalSupply, maxSupply, isPaused, isSupplyLimit, isOG, isWL },
  } = useMint();

  const handleButtonClick = useCallback(async () => {
    if (isConnected) await handleMinting();
    else await connect();
  });

  const [mintCount, setMintCount] = useState(1);

  const handleMinting = useCallback(async () => {
    console.log("trying to mint");
    if (stage == 0) return;

    console.log("minting ", mintCount);

    // Signal the wallet to initiate a minting transaction
    const didMint = await mint(mintCount);

    if (didMint) {
      console.log("minted");
    }
  }, [mint, mintCount]);

  const [mintButtonLabel, setMintButtonLabel] = useState("Mint");

  useEffect(() => {
    if (!isConnected) setMintButtonLabel("Connect Wallet");
    else if (isPaused) setMintButtonLabel("Minting Paused");
    else if (isSupplyLimit) setMintButtonLabel("Minted Out");
    else setMintButtonLabel("Mint " + mintCount);
  }, [isConnected, isPaused, isSupplyLimit, mintCount]);

  return (
    <div className='back_other'>
      <Navbar/>
      <br/>
      <div className='container'>   <h1 style={{textAlign:"center"}}>Bullygon Mint</h1>
      <br/>
        <Grid container spacing={2}>
        <Grid item md={6} lg={6} xs={12} sm={12} >
          
       
          <img src={Mints}  className='mintimg'/>
       
</Grid>
<Grid item md={6} lg={6} xs={12} sm={12} >
          
<br/>      <br/>
          {isConnected && stage == 1 ? (
             <div className="" style={{textAlign:"center"}}>
               <h1 className="" style={{textAlign:"center" , fontWeight:"700"}}>OG Whitelist Mint</h1>
               <h1 className="" style={{textAlign:"center" , fontWeight:"700"}}>Mint Limit: 5</h1>
             </div>
           ) : isConnected && stage == 2 ? (
             <div className="" style={{textAlign:"center"}}>
               <h1 className="" style={{textAlign:"center" , fontWeight:"700"}}>Whitelist Mint</h1>
               <h1 className="" style={{textAlign:"center" , fontWeight:"700"}}>Mint Limit: 4</h1>
               <h1 className="" style={{textAlign:"center" , fontWeight:"700"}}>Mint Cost: 25 matic</h1>
             </div>
           ) : isConnected && stage == 3 ? (
             <div className="" style={{textAlign:"center"}}>
               <h1 className="" style={{textAlign:"center" , fontWeight:"700"}}>Public Mint</h1>
               <h1 className="" style={{textAlign:"center" , fontWeight:"700"}}>Mint Cost: 25 matic</h1>
             </div>
           ) : (
             <div className="" style={{textAlign:"center"}}>
               <h1 className="" style={{textAlign:"center" , fontWeight:"700"}}>Mint not started yet</h1>
             </div>
           )
          }

          <h1 className="" style={{textAlign:"center" , fontWeight:"700"}}>
            <p>
              Total Remaining{" "}
              {isConnected ? (
                (maxSupply - totalSupply).toLocaleString("en-US") + " / " + maxSupply?.toLocaleString("en-US")
              ) : (
                <Skeleton width={100} />
              )}
            </p>
          </h1>
       <br/>
       <br/>
        {isConnected && stage == 1 ? (
         <div className="" style={{textAlign:"center"}}>
           <h3 className="" style={{textAlign:"center" , fontWeight:"700"}}>{isOG ? "Account is whitelisted" : "Account not whitelisted"}</h3>
         </div>
         ) : isConnected && stage == 2 ? (
           <div className="" style={{textAlign:"center"}}>
             <h3 className="" style={{textAlign:"center" , fontWeight:"700"}}>{isWL ? "Account is whitelisted" : "Account not whitelisted"}</h3>
           </div>
         ) : (
           <div className="" style={{textAlign:"center"}}>
            <p> </p>
           </div>
         )
        }
       <br/>
       <div className=''>
          {!isConnected ? (
           <div className="" style={{textAlign:"center"}}>
             <p>Connect your Polygon wallet</p>
             <a onClick={handleButtonClick}>{mintButtonLabel}</a>
           </div>
           ) : (
             <div className="centeritall">
               <button
                 onClick={() =>
                   setMintCount(mintCount > 1 ? mintCount - 1 : 1)
                 }
               >
                 -
               </button>
               &nbsp; &nbsp;
               <button
                 onClick={() =>
                   setMintCount(mintCount < 50 ? mintCount + 1 : 50)
                 }
               >
                 +
               </button>
             </div>
           )}
          <br/>
          <br/>
          <div className='centeritall'>
          <button className="button-86" role="button" disabled={(isPaused) || (isSupplyLimit) || (stage == 1 && !isOG) || (stage == 2 && !isWL)} onClick={handleButtonClick}>
            {mintButtonLabel}
          </button>

          </div>
      
       </div>
</Grid>
        </Grid>
    

</div>
     


       <br/>
       <br/> <br/>
       <br/>
       </div>
  )
}

export default Mint