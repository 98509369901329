import React from 'react'
import { Grid } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import token from './tok1.jpg'
import tokenn from './tok2.jpg'
import  Dannyell from './Dannyells.jpg'
import WhiteWolf from './Whitewolfs.jpg'
import MaticMan from './MaticMan.jpg'
import xMaverick from './0xMaverick.jpg'
function TeamPage() {
  return (
    <div> <Navbar />
    <div className='container'>    <Grid container spacing={2}>
          <Grid item md={12} lg={12} xs={12} sn={12}>
        
            <br />
            <br />
            <h1 className="about"> $BULL TOKENOMICS</h1>
            <br />
            <br />          
       </Grid>
       
    <Grid item md={12} lg={12} xs={12} sn={12}>
        <br/>  
        <h1 className="about">  Tokenomics update coming soon</h1>
        <br/>   <br/>     <br/>   <br/>   <br/>   <br/>    <br/>   <br/>   <br/>   <br/>    <br/>   <br/>   <br/>   <br/>    <br/>   <br/>   <br/>   <br/>    <br/>   <br/>   <br/>   <br/>    <br/>   <br/>   <br/>   <br/>    <br/>   <br/>   <br/>   <br/>    <br/>   <br/>   <br/>   <br/>    <br/>   <br/>   <br/>   <br/>    <br/>   <br/>   <br/>   <br/>
         <br/>
        
    </Grid>


        </Grid></div>

    
        <br/>   <br/>   <br/>   <br/>
    </div>
  )
}

export default TeamPage