import React from 'react'
import { Grid } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BsTwitter, BsDiscord } from "react-icons/bs";
import x from './x.png'
import CAPRAW from './CAPRAW.jpg'
import  Dannyell from './Dannyells.jpg'
import WhiteWolf from './Whitewolfs.jpg'
import MaticMan from './MaticMan.jpg'
import xMaverick from './0xMaverick.jpg'
function TeamPage() {
  return (
    <div> <Navbar />
    <div className='container'>    <Grid container spacing={2}>
          <Grid item md={12} lg={12} xs={12} sn={12}>
        
            <br />
            <br />
            <h1 className="about">BULLygon with us</h1>
            <br />
            <br />          
       </Grid>
       <Grid item md={4} lg={4} xs={12} sm={12}>
        
       <Card sx={{ maxWidth: 345 }} className='backofbulls'>
      <CardMedia
        sx={{ height: 300 }}
        image={CAPRAW}
        title="CAPRAW"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        CAPRAW
        </Typography>
        <Typography variant="body2" color="">
        Artist/ Founder
        </Typography>
        <Typography variant="body2" color="text.secondary">
       <br/>
          <a
            href="https://twitter.com/CAPRAW_designs"
            style={{ fontWeight: "800", textDecoration: "none" }}
          
            target="_blank"
          >
    <img src={x} style={{width:"37px" }} className='x-logo-team' />
          </a>
    
         </Typography>
      </CardContent>
     
    </Card>        
   </Grid>
   <Grid item md={4} lg={4} xs={12} sm={12}>
        
        <Card sx={{ maxWidth: 345 }} className='backofbulls'>
       <CardMedia
         sx={{ height: 300 }}
         image={Dannyell}
         title="CAPRAW"
       />
       <CardContent>
         <Typography gutterBottom variant="h5" component="div">
         Dannyell
         </Typography>
         <Typography variant="body2" color="">
         Co- Founder/ Marketing Lead
         </Typography>
         <Typography variant="body2" color="text.secondary">
       <br/>
          <a
            href="https://twitter.com/OG_dannyell"
            style={{ fontWeight: "800", textDecoration: "none" }}
          
            target="_blank"
          >
               <img src={x} style={{width:"37px" }} className='x-logo-team' />
          </a>
    
         </Typography>
       </CardContent>
      
     </Card>        
    </Grid>
    <Grid item md={4} lg={4} xs={12} sm={12}>
        
        <Card sx={{ maxWidth: 345 }} className='backofbulls'>
       <CardMedia
         sx={{ height: 300 }}
         image={WhiteWolf}
         title="CAPRAW"
       />
       <CardContent>
         <Typography gutterBottom variant="h5" component="div">
         WhiteWolf
         </Typography>
         <Typography variant="body2" color="">
         Community Manager
         </Typography>
         <Typography variant="body2" color="text.secondary">
       <br/>
          <a
            href="https://twitter.com/whytewlf"
            style={{ fontWeight: "800", textDecoration: "none" }}
          
            target="_blank"
          >
              <img src={x} style={{width:"37px" }} className='x-logo-team' />
          </a>
    
         </Typography>
       </CardContent>
      
     </Card>        
    </Grid>

    <Grid item md={4} lg={4} xs={12} sm={12}>
        <br/>   <br/>
        <Card sx={{ maxWidth: 345 }} className='backofbulls'>
       <CardMedia
         sx={{ height: 300 }}
         image={MaticMan}
         title="CAPRAW"
       />
       <CardContent>
         <Typography gutterBottom variant="h5" component="div">
         Matic Man
         </Typography>
         <Typography variant="body2" color="">
         Advisor
         </Typography>
         <Typography variant="body2" color="text.secondary">
       <br/>
          <a
            href="https://twitter.com/thematicman"
            style={{ fontWeight: "800", textDecoration: "none" }}
          
            target="_blank"
          >
             <img src={x} style={{width:"37px" }} className='x-logo-team' />
          </a>
    
         </Typography>
       </CardContent>
      
     </Card>        
    </Grid>
    <Grid item md={4} lg={4} xs={12} sm={12}>
        <br/>   <br/>
        <Card sx={{ maxWidth: 345 }} className='backofbulls'>
       <CardMedia
         sx={{ height: 300 }}
         image={xMaverick}
         title="CAPRAW"
       />
       <CardContent>
         <Typography gutterBottom variant="h5" component="div">
         0xMaverick
         </Typography>
         <Typography variant="body2" color="">
         Advisor
         </Typography>
         <Typography variant="body2" color="text.secondary">
       <br/>
          <a
            href="https://twitter.com/0xMaverick_GM"
            style={{ fontWeight: "800", textDecoration: "none" }}
          
            target="_blank"
          >
              <img src={x} style={{width:"37px" }} className='x-logo-team' />
          </a>
    
         </Typography>
       </CardContent>
      
     </Card>        
    </Grid>
        </Grid></div>

    
        <br/>   <br/>   <br/>   <br/>
    </div>
  )
}

export default TeamPage