import React from 'react'

import { Grid } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import CAPRAW from './CAPRAW.jpg'
import  Dannyell from './Dannyells.jpg'
import WhiteWolf from './Whitewolfs.jpg'
import MaticMan from './MaticMan.jpg'
import xMaverick from './0xMaverick.jpg'

import Rock from './rock.jpg'
import AOS from "aos";
AOS.init({
  duration: 2500,
});

function TeamPage() {
  return (
    <div> <Navbar />
    <div className='container'>   
     <Grid container spacing={2}>
      
          <Grid item md={12} lg={12} xs={12} sn={12}>
          <br /> <br />
          <h1 className="about">Frequently Asked Questions</h1>
          <br />
            <br />
        <div className='TOP_FAQ'> 
         <br />
            <br />  <br />
            <br />  <br />
            <br />  <br />
            <br />  <br />
            <br />  <br />
            <br />  <br />
            <br />  <br />
            <br />  <br />
            <br />  <br />
            <br />  <br />
            <br />  <br />
            <br /></div>


          
            <br />
            <br />          
       </Grid>
     
     <Grid item md={12} lg={12} xs={12} sm={12} >


      <div className='pad_faq'>
      <br/>
      <h1 className=" about_q"> What is the total supply?</h1>
<br/>
<p className=" about_a"> 2500 BULLs 🐂</p>
      </div>

     </Grid>


     <Grid item md={12} lg={12} xs={12} sm={12} >
      <div className='pad_faq'>
      <br/>
      <h1 className=" about_q"> Mint Price ?</h1>
<br/>
<p className=" about_a"> 25 Matic</p>
      </div>

     </Grid>


     
     <Grid item md={12} lg={12} xs={12} sm={12} >
      <div className='pad_faq'>
      <br/>
      <h1 className=" about_q"> Mint date ?</h1>
<br/>
<p className=" about_a"> TBA</p>
      </div>

     </Grid>


     <Grid item md={12} lg={12} xs={12} sm={12} >
      <div className='pad_faq'>
      <br/>
      <h1 className=" about_q"> How will my BULLygon Nfts upgrade?</h1>
<br/>
<p className=" about_a"> You get to feed your BULLygon Nfts with an X amount of $BULL Token to upgrade to a new level.
Ps; there will be 6 levels.
</p>
      </div>

     </Grid>



     <Grid item md={12} lg={12} xs={12} sm={12} >
      <div className='pad_faq'>
      <br/>
      <h1 className=" about_q"> When will the $BULL token go live and tradable?</h1>
<br/>
<p className=" about_a"> An Announcement will be made regarding this after the NFT Launch.
</p>
      </div>

     </Grid>


     <Grid item md={12} lg={12} xs={12} sm={12} >
      <div className='pad_faq'>
      <br/>
      <h1 className=" about_q">How many portion of the $BULL token will each Nft come with?</h1>
<br/>
<p className=" about_a">  An undisclosed amount, would be revealed after mint 🐂
</p>
      </div>

     </Grid>


     <Grid item md={12} lg={12} xs={12} sm={12} >
      <div className='pad_faq'>
      <br/>
      <h1 className=" about_q"> How do I get WL?</h1>
<br/>
<p className=" about_a">   Every detail regarding this can be found in the discord.
</p>
      </div>

     </Grid>
        </Grid> 
        </div>

    
        <br/>   <br/>   <br/>   <br/>
    </div>
  )
}

export default TeamPage