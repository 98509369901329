import React from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const LandingPage = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  return (
    <div>
      {" "}
      {!smallScreen && (
        <div className="mainContentfof">
          <div className="pageConfof"></div>
        </div>
      )}
      {/* This is used For the mobile view Blue print Map*/}
      {smallScreen && (
        <div className="mainContentmobfof">
          <div className="pageConmobfof"></div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
