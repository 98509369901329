import React from 'react'
import Navbar from '../Component/Navbar/Navbar'

import { Grid } from '@mui/material'

function Walletchecker() {
  return (
    <div className='back_other_wl'>
        <Navbar/>
       
<div className='container'>
<Grid container >
       <Grid item md={12} lg={12} xs={12} sm={12} >
       <br/>
       <br/>
       <div className='centeritall'>  
       <h1 className="about">Whitelist Checker</h1>
       </div>
    
    
       <br/>
       <br/>

       <div className='centeritall'>  
       <form  className='div'>
    
    <input type="text" id="fname" name="firstname" placeholder="0X......"/>



  
    <input type="submit" value="Submit"/>

<br/>

  </form>
       </div>
     
        
</Grid>




       </Grid>

</div>
     


       <br/>
       <br/> <br/>
       <br/>
       </div>
  )
}

export default Walletchecker