import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme";
import "./App.css";
import LandingPage from "./Pages/LandingPage";
import ForoFor from "./Pages/404";
import Home from "./Pages/index";
import FAQ from './Pages/Faq'
import TOKENOMICS from './Pages/TOKENOMICS'
import Roadmap from './Pages/ROADMAP'
import Mint from './Mint/Mint'
import Walletchecker from './Pages/Walletchecker';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SuartaMainSlider from "./Pages/SliderPage";

import { configureChains, createClient, WagmiConfig } from "wagmi";
import { polygon, polygonMumbai } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

const { chains, provider } = configureChains([polygon], [publicProvider()]);
//console.log("chain - ", chains[0].rpcUrls.default.http[0])
//fix rpc url
//chains[0].rpcUrls.default.http[0] = 'https://endpoints.omniatech.io/v1/matic/mumbai/public';
//console.log("chain rpc edited - ", chains[0].rpcUrls.default.http[0])


const client = createClient({
  provider,
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    /*
      new CoinbaseWalletConnector({
        chains,
        options: {
          appName: "wagmi",
        },
      }),
      new WalletConnectConnector({
        chains,
        options: {
          projectId: "bullygon",
        },
      }),
      new InjectedConnector({
        chains,
        options: {
          name: "Injected",
          shimDisconnect: true,
        },
      }),*/
  ],
});

const App = () => {
  return (
    <WagmiConfig client={client}>
    <ThemeProvider theme={theme}>
      <div>
        <>
          <Router>
            <Routes>
              <Route path="/" exact={true} element={<LandingPage />} />
              <Route path="Team" exact={true} element={<Home />} />
              <Route path="FAQ" exact={true} element={<FAQ />} />
              <Route path="TOKENOMICS" exact={true} element={<TOKENOMICS />} />
              <Route
                path="BULLygon"
                exact={true}
                element={<SuartaMainSlider />}
              />
               <Route
                path="PENMAP"
                exact={true}
                element={<Roadmap />}
              />
 <Route
                path="Mint"
                exact={true}
                element={<Mint />}
              />


<Route
                path="Walletchecker"
                exact={true}
                element={<Walletchecker />}
              />
              <Route path="*" exact={true} element={<ForoFor />} />
            </Routes>
          </Router>
        </>
      </div>
    </ThemeProvider>
    </WagmiConfig>
  );
};

export default App;
